import { toFixed } from 'egenie-common';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import { getPrice } from '../../../utils';
import styles from './index.less';

interface IPriceProps {
  discountPrice: number | string;
  price: number;
  isShowText: boolean;
  activityPrice?: number;
}

// 款式价格
export const Price = observer(({ discountPrice, price, isShowText, activityPrice }: IPriceProps): ReactElement => {
  const _price = getPrice(price, discountPrice, activityPrice);
  const max = toFixed(_price, 2)
    .toString()
    .split('.')[0];
  const min = toFixed(_price, 2)
    .toString()
    .split('.')[1];
  return (
    <div className={`${styles.flex} ${styles.faifEnd} `}>
      {
        !discountPrice && !activityPrice && isShowText && (
          <span
            className={`${styles.font12} ${styles.mr4}`}
          >
            进货价
          </span>
        )
      }
      <span className={`${styles.clRed} ${styles.font500} ${styles.flex} ${styles.faifEnd}`}>
        {
          Boolean(activityPrice) && (
            <div className={`${styles.specialPrice} ${styles.clRed} ${styles.mr4}`}>
              活动
            </div>
          )
        }
        {
          Boolean(discountPrice) && !activityPrice && (
            <div className={`${styles.specialPrice} ${styles.clRed} ${styles.mr4}`}>
              专享
            </div>
          )
        }
        <span className={styles.font14}>
          ¥
        </span>
        <span className={styles.font16}>
          {max}
        </span>
        <span className={`${styles.font12} ${styles.lh14}`}>
          .
          {min}
        </span>
        {
          (Boolean(discountPrice) || Boolean(activityPrice)) && (
            <span className={`${styles.font12} ${styles.ml4} ${styles.originPrice} ${styles.clSecondary} ${styles.lh14}`}>
              {toFixed(price, 2)}
            </span>
          )
        }

      </span>
    </div>
  );
});
