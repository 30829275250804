import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { scrollToAnchor } from '../../utils';
import { api } from '../../utils/api';
import { FilterStore } from '../components/filter/store';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import { StallFilterItems, StyleFilterItems } from './constant';
import type { ParentFtCategoryProps, StallCategoryProps, TagsProps } from './interface';

export class MarketStore {
  constructor() {
    this.init(50);
  }

  @observable public tabs = [
    {
      label: '按款式展示',
      key: 'filterByStyle',
    },
    {
      label: '按档口展示',
      key: 'filterByStall',
    },
  ]; // tab

  @observable public loading = false; // 页面loading

  @observable public marketName = ''; // 市场名

  @observable public marketIds = []; // 市场id

  @observable public marketIntroduce = ''; // 市场介绍

  @observable public category = []; // 类目

  @observable public activeKey = 'filterByStyle'; // 当前选中tab

  @observable public list = []; // 款式列表、档口列表

  public paginationStore = new PaginationStore({ parent: this }); // 分页store

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public totalCount = 0; // 列表总数

  @observable public totalPageCount = 0; // 分页总数

  @observable public invalid = [
    'null',
    '',
  ];

  @action
  public init = (pageSize) => {
    const search = new URLSearchParams(window.location.href.split('?')[1]);

    // 获取传入参数
    this.marketName = search.get('marketName');
    this.marketIds = JSON.parse(search.get('marketIds'));
    this.marketIntroduce = search.get('marketIntroduce');

    document.title = `${this.marketName}-衫海精`;

    // 获取一级分类
    this.getParentFtCategory();

    // 获取风格、季节、年龄、渠道、性别
    this.getStyle();

    // 获取档口分类
    this.getStallParentFtCategory();

    // 重置分页
    this.resetPage();

    // 搜索
    this.onPageChange(1, pageSize);
  };

  // 处理前端3级类目
  private dealFtCategory = (categoryList: ParentFtCategoryProps[]) => {
    return categoryList?.map((category) => {
      if (!category.child || category.child.length === 0) {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: [],
        };
      } else {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: this.dealFtCategory(category.child),
        };
      }
    });
  };

  // 获取品类
  @action
  public getParentFtCategory = async() => {
    const req = await request<BaseData<ParentFtCategoryProps[]>>({
      url: api.queryMoreCategory,
      method: 'get',
    });
    this.category = req.data;
    const ftCategoryIds = this.dealFtCategory(req.data);
    this.filter.updateFilterItems({ ftCategoryIds });
  };

  @action
  public getFtCategory = (categoryId: number) => {
    let parentFtCategoryId: number,
      ftCategoryIds = [];
    const currentCategory = this.category?.find((v) => v.id === categoryId);
    if (currentCategory) {
      parentFtCategoryId = categoryId;
    } else {
      for (let i = 0; i < this.category.length; i++) {
        const secondList = this.category[i].child;
        const secondCategory = secondList?.find((v) => v.id === categoryId);
        if (secondCategory) {
          parentFtCategoryId = this.category[i].id;
          ftCategoryIds = [categoryId];
          break;
        } else {
          for (let j = 0; j < secondList.length; j++) {
            const thirdCategory = secondList[j].child?.find((v) => v.id === categoryId);
            if (thirdCategory) {
              parentFtCategoryId = this.category[i].id;
              ftCategoryIds = [categoryId];
              break;
            }
          }
        }
      }
    }
    return {
      parentFtCategoryId,
      ftCategoryIds,
    };
  };

  // 获取风格、季节、年龄、渠道、性别
  @action
  public getStyle = async() => {
    const req = await request<BaseData<TagsProps[]>>({
      url: `${api.getTagGroup}?type=3`,
      method: 'get',
    });

    // 给当前激活tab筛选项改变下拉值
    this.filter.updateFilterItems({
      style: req.data.filter((item) => item.tagGroupId === 10001)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableSeason: req.data.filter((item) => item.tagGroupId === 10017)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableAge: req.data.filter((item) => item.tagGroupId === 100019)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableChannel: req.data.filter((item) => item.tagGroupId === 10015)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableGender: req.data.filter((item) => item.tagGroupId === 10018)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
    });
  };

  // 获取档口类别
  @action
  public getStallParentFtCategory = async() => {
    const req = await request<BaseData<{ layoutDetailList: StallCategoryProps[]; }>>({
      url: '/api/mall/rest/square/anon/categoryList',
      method: 'get',
    });

    // 给当前激活tab筛选项改变下拉值
    this.filter.updateFilterItems({
      mainCategoryId: req.data.layoutDetailList.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    });
  };

  // 改变tab
  @action
  public changeTabs = (e) => {
    this.activeKey = e;

    // 重置筛选项
    this.filter.resetFilterItems(this.activeKey === 'filterByStyle' ? StyleFilterItems(this) : StallFilterItems(this));

    this.init(this.activeKey === 'filterByStyle' ? 50 : 10);
  };

  /**
   * 根据当前选中的tab获取款式\档口
   * @param params 查询条件
   */
  @action
  public getList = async(params: any = {}) => {
    const { priceRange = []} = params;
    this.loading = true;
    const {
      parentFtCategoryId,
      ftCategoryIds,
    } = this.getFtCategory(Number(params.ftCategoryIds));
    try {
      const req = await request<PaginationData>({
        url: this.activeKey === 'filterByStyle' ? '/api/mall/rest/square/anon/product/pageGoodsV2' : '/api/mall/rest/square/anon/allVendorPage',
        method: 'post',
        data: {
          ...params,
          page: params.page,
          pageSize: params.pageSize,
          minProductPrice: priceRange[0],
          maxProductPrice: priceRange[1],
          businessDistrictId: this.marketIds?.toString(),
          sidx: params.sidx.split('-')[0],
          sord: params.sidx.split('-')[1],
          parentFtCategoryId,
          ftCategoryIds,
        },
      });
      this.list = req.data.list;
      this.totalCount = req.data.totalCount;
      this.totalPageCount = req.data.totalPageCount;
    } finally {
      this.loading = false;
    }
  };

  // 改变分页
  @action
  public onPageChange = (page: number, pageSize: number): void => {
    this.getList({
      ...this.filter.params,
      page,
      pageSize,
    });
    scrollToAnchor('root');
  };

  // 重置分页
  @action
  public resetPage = () => {
    this.paginationStore.current = 1;
    this.paginationStore.pageSize = this.activeKey === 'filterByStyle' ? 50 : 10;
  };

  // 筛选
  public filter = new FilterStore({
    filterItems: StyleFilterItems(this),
    handleSearch: () => {
      // 重置分页
      this.resetPage();

      // 搜索
      this.onPageChange(1, this.activeKey === 'filterByStyle' ? 50 : 10);
    },
  });
}
