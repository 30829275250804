import React from 'react';

/**
 * @name 空白页，加载中展示
 * @param props
 * @returns
 */
export const BlankSegment: React.FC<Partial<{ width: number; height: number; }>> = (props) => {
  const { width, height } = props;
  return (
    <div style={{
      width: width ? width : '100%',
      height: height || 400,
    }}
    />
  );
};
