import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';
import type { FilterStore } from './store';

// 商品通用筛选项，可配置
@observer
export class Filter extends React.Component<{ store?: FilterStore; }> {
  render() {
    const { store } = this.props;
    return (
      <Form className={styles.filter}>
        <div
          className={`${styles.filterContainer} ${styles.group} ${store.isCollapse ? styles.up : styles.down}`}
          id="filterContainer"
        >
          {
            store.actualFilterItems.map((item, index) => (
              <Form.Item
                className={styles.item}
                key={nanoid()}
                label={item.label}
                name={item.field}
                style={{ opacity: (!item.collapseItem || !store.isCollapse) ? 1 : 0 }}
              >
                {store.formItemContent(item)}
              </Form.Item>
            ))
          }
        </div>
        {
          store.actualFilterItems.some((item) => item.collapseItem) && (
            <div className={styles.more}>
              <span
                className={styles.moreBtn}
                onClick={() => {
                  store.isCollapse = !store.isCollapse;
                }}
              >
                更多筛选
                {
                  store.isCollapse ? <DownOutlined className={styles.collapseIcon}/> : <UpOutlined className={styles.collapseIcon}/>
                }
              </span>
            </div>
          )
        }
      </Form>
    );
  }
}
