import { action, computed, observable } from 'mobx';
import React from 'react';
import { FilterInputNumberGroup } from './components/filterInputNumberGroup';
import { FilterSelect } from './components/filterSelect/index';
import { FilterTreeSelect } from './components/filterTreeSelect/index';

export class FilterStore {
  constructor(parent) {
    this.parent = parent;
    this.originFilterItems = [...parent.filterItems];
  }

  @observable public parent;

  @observable public originFilterItems = []; // 筛选项配置

  @observable public isCollapse = true; // 是否折叠

  @computed
  public get actualFilterItems() {
    return this.originFilterItems.filter((item) => item);
  }

  // 有效参数
  @computed
  public get params(): any {
    const currentParams = {};
    this.actualFilterItems.filter((item) => item.value !== undefined).forEach((item) => {
      currentParams[item.field] = item.value;
    });
    return currentParams;
  }

  // 根据类型获取内容
  @action
  public formItemContent = (info): JSX.Element => {
    switch (info.type) {
      case 'select':
        return (
          <FilterSelect
            info={info}
            onSearch={() => this.parent.handleSearch()}
          />
        );
      case 'inputNumberGroup':
        return (
          <FilterInputNumberGroup
            info={info}
            onSearch={() => this.parent.handleSearch()}
          />
        );
      case 'treeSelect':
        return (
          <FilterTreeSelect
            info={info}
            onSearch={() => this.parent.handleSearch()}
          />
        );
      default:
        return <span/>;
    }
  };

  /**
 *  修改选择框下拉项
 * @param params 外面设置键值对
 */
  @action
  public updateFilterItems = (params) => {
    this.originFilterItems.forEach((item) => {
      if (Object.keys(params).includes(item.field)) {
        item.data = params[item.field];
      }
    });
  };

  /**
 *  修改输入项参数
 * @param params 外面设置键值对
 */
  @action
  public updateFilterParams = (params) => {
    this.originFilterItems.forEach((item) => {
      if (Object.keys(params).includes(item.field)) {
        item.value = params[item.field];
      }
    });
  };

  /**
   * 重置筛选项
   * @param filterItems 重置为 指定筛选项
   */
  @action
  public resetFilterItems = (filterItems) => {
    this.originFilterItems = filterItems;
  };
}
