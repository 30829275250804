import { Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { debounce } from 'lodash';

export const FilterSelect = observer((props) => {
  const {
    onSearch,
    info,
  } = props;

  const [
    value,
    setValue,
  ] = useState('' || []);

  const [
    open,
    setOpen,
  ] = useState(false);

  useEffect(() => {
    setValue(info.value);
  }, [info.value]);

  const onMouseEnter = debounce(() => setOpen(true), 300);

  const onMouseLeave = () => {
    setOpen(false);
    onMouseEnter.cancel();
  };

  return (
    <Select
      allowClear={info.allowClear}
      className={styles.select}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      maxTagCount="responsive"
      mode={info.mode}
      onChange={(e) => {
        // 更新展示数据
        info.value = e;
        setValue(e);

        // 传出数据
        info.onChangeCallback && info.onChangeCallback(e);

        // 搜索
        onSearch();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      open={open}
      optionFilterProp="label"
      options={info.data}
      placeholder={info.placeholder || '请选择'}
      popupClassName={styles.selectDropdown}
      showArrow
      showSearch={info.showSearch}
      value={value}
    />
  );
});
