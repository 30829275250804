import { Button, Popover } from 'antd';
import React, { memo } from 'react';
import LazyLoad from 'react-lazyload';
import clock from '../../../assets/images/clock.png';
import eye from '../../../assets/images/eye.png';
import rightArrow from '../../../assets/images/rightArr.png';
import { NoLackTag, Price } from '../index';
import { Tag } from '../productCard/index';
import styles from './index.less';
import { replacePath } from '../../../utils';

type LabelList = Array<{
  id: number;
  tagColor: string;
  tagName: string;
}>;

/**
 * @param type 类型：dynamic动态、detail详情
 * @param className 类名
 * @param style 行类样式
 * @param lineCount 一行最多展示多少个卡片,也可以用宽度限制
 * @param width 宽度
 * @param list 列表
 * @param vendorName 档口名称
 * @param vendorId 档口id
 * @param seenNum 查看数量
 * @param createdAt 创建时间
 * @param recommendDesc 推荐语
 * @param showLabel 是否展示档口标签
 * @param labelList 标签
 * @param goodsPutOnNumWeek 最近上新
 * @param mainCategoryName 主营
 * @param discountPercent 折扣
 * @param showNameEnterIcon 是否展示rightArrow
 * @callback onClickVendorName 点击vendorName
 * @param returnable 是否可退0: 不可退、1可退部分、2无忧退货
 * @param returnRate 可退比例
 * @param marketLabelName 市场名称
 * @param showReturnInfo 是否展示可退货信息
 * @param unionFlag 展示不缺货标签
 *
 * @returns
 */
// 档口动态
export const VendorRelated: React.FC<Partial<{
  type: 'dynamic' | 'detail';
  className: string;
  width: number;
  vendorId: number;
  lineCount: number;
  seenNum: number;
  vendorName: string;
  createdAt: string;
  recommendDesc: string;
  mainCategoryName: string;
  goodsPutOnNumWeek: number;
  discountPercent: number | string;
  showNameEnterIcon: boolean;
  showLabel: boolean;
  showBindBtn: boolean;
  onClickBindLabel: (vendorId: number) => void;
  onClickVendorName: (vendorId?: number, dynamicId?: string | number) => void;
  labelList: LabelList;
  listContent: React.ReactNode;
  marketLabelName: string;
  returnable: 0 | 1 | 2; // 是否支持退货(0.不支持  1.供应商支持退货 2.支持无忧退货
  returnRate: number;
  showReturnInfo: boolean;
  dynamicId: string | number;
  unionFlag: number;
}>> = (props) => {
  const {
    type = 'dynamic',
    className,
    width,
    lineCount = 5,
    listContent,
    showLabel,
    vendorId,
    vendorName,
    seenNum,
    mainCategoryName,
    goodsPutOnNumWeek,
    createdAt,
    recommendDesc,
    discountPercent,
    labelList,
    onClickVendorName,
    onClickBindLabel,
    showNameEnterIcon = false,
    showBindBtn,
    showReturnInfo,
    marketLabelName,
    returnable,
    returnRate,
    dynamicId,
    unionFlag,
  } = props;

  // 点击绑定标签
  const onClickBind = () => {
    onClickBindLabel && onClickBindLabel(vendorId);
  };
  const onClickVendor = () => {
    if (onClickVendorName) {
      onClickVendorName(vendorId, dynamicId || '');
    } else {
      window.open(replacePath(`/egenie-ts-vogue/stallDetail/index?vendorId=${vendorId}&dynamicId=${dynamicId || ''}`));
    }
  };
  return (
    <div
      className={`${className ? className : ''} ${styles.vendorContainer}`}
      style={{ width: width ? width : ((lineCount || 5) * 232 - 12) }}
    >
      <div className={styles.vendorTitleLine}>
        <div
          className={styles.vendor}
          onClick={onClickVendor}
        >
          <div className={styles.vendorNameContainer}>
            <span className={styles.vendorName}>
              {vendorName}
            </span>
            {type === 'detail' && (
              <>
                {discountPercent && discountPercent !== 100 && (
                  <span className={styles.discount}>
                    {`专享${discountPercent || '--'}折`}
                  </span>
                )}
                {showNameEnterIcon && (
                  <img
                    className={styles.rightArr}
                    height={16}
                    src={rightArrow}
                    width={16}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {type === 'dynamic' && (
          <>
            {
              (seenNum || seenNum === 0) && (
                <div className={styles.seenAndTime}>
                  <img src={eye}/>
                  {seenNum}
                </div>
              )
            }
            {
              createdAt && (
                <div className={styles.seenAndTime}>
                  <img src={clock}/>
                  {createdAt}
                </div>
              )
            }
          </>
        )}
      </div>
      {
        type === 'dynamic' && (
          <div className={styles.desc}>
            {recommendDesc}
          </div>
        )
      }
      {type === 'detail' && (
        <>
          <div className={styles.mainCategory}>
            <span>
              {`主营：${mainCategoryName || '-'}`}
            </span>
            <span>
              {`最新上款：${goodsPutOnNumWeek ?? '-'}款`}
            </span>
          </div>
          {
            showLabel && (
              <div className={styles.labelContainer}>
                {labelList && (<AssignLabel labelList={labelList}/>)}
                {showBindBtn && (
                  <div className={styles.bindButton}>
                    <Button
                      onClick={onClickBind}
                      size="small"
                    >
                      绑定标签
                    </Button>
                  </div>
                )}
              </div>
            )
          }
          <div className={styles.labelWrapper}>
            {
              showReturnInfo && (
                <div className={styles.labelContainer}>
                  <Tag
                    marketLabelName={marketLabelName}
                    type="market"
                  />
                  <Tag
                    returnRate={returnRate}
                    returnable={returnable}
                    type="return"
                  />
                </div>
              )
            }
            {unionFlag ? <NoLackTag/> : null}
          </div>
        </>
      )}
      <div>
        {listContent}
      </div>
    </div>
  );
};

/**
 * @param className 类名
 * @param goodsId 商品id
 * @param dynamicId 动态id
 * @param mainPicUrl 主图图片
 * @param visibleType 可见度类型，1公开、2私密
 * @param picGroupNum 组图数量
 * @param activityPrice 活动价
 * @param discountPrice 专享价
 * @param price 最小批发价
 * @callback onClick 点击后的回调
 * @returns
 */
// 档口卡片
export const DynamicProductCard: React.FC<Partial<{
  className: string;
  goodsId: number;
  dynamicId: string | number;
  mainPicUrl: string;
  visibleType: 1 | 2;
  picGroupNum: number;
  activityPrice: number;
  discountPrice: number | string;
  price: number;
  onClick: (goodsId: number, dynamicId: number | string) => void;
  overflow?: boolean;
}>> = (props) => {
  const {
    className,
    goodsId,
    dynamicId,
    mainPicUrl,
    visibleType,
    picGroupNum,
    activityPrice,
    discountPrice,
    onClick,
    price,
    overflow,
  } = props;

  const onClickCard = () => {
    if (onClick) {
      onClick(goodsId, dynamicId || '');
    } else {
      window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}&dynamicId=${dynamicId || ''}`));
    }
  };
  return (
    <div
      className={`${styles.dynamicCard} ${className}`}
      onClick={onClickCard}
    >
      <div className={styles.imgContainer}>
        <LazyLoad
          height={200}
          overflow={overflow}
        >
          <div className={styles.imgWrap}>
            <img src={mainPicUrl}/>
          </div>
        </LazyLoad>
        {
          visibleType && visibleType === 2 && (
            <span className={styles.privateIcon}>
              密
            </span>
          )
        }
        {
          picGroupNum && picGroupNum > 1 && (
            <span className={styles.picGroup}>
              {picGroupNum}
              套图
            </span>
          )
        }
      </div>
      <Price
        activityPrice={activityPrice}
        discountPrice={discountPrice}
        isShowText={false}
        price={price}
      />
    </div>
  );
};

// 绑定标签
const AssignLabel: React.FC<{
  labelList: Array<{
    id: number;
    tagColor: string;
    tagName: string;
  }>;
}> = memo((props) => {
  const { labelList = []} = props;

  // render标签
  const renderLabel = (labelList: Array<{
    id: number;
    tagColor: string;
    tagName: string;
  }>): React.ReactNode => {
    return (
      <div>
        {
          labelList?.map((item) => {
            return (
              <span
                className={styles.labelItem}
                key={item.id}
                style={{
                  color: item.tagColor,
                  background: `${item.tagColor}1A`,
                }}
              >
                {item.tagName}
              </span>
            );
          })
        }
      </div>
    );
  };

  // 标签数量
  const labelCount = labelList?.length || 0;
  if (labelList && labelCount > 5) {
    return (
      <div className={styles.label}>
        {
          renderLabel(labelList.slice(0, 5))
        }
        <Popover
          content={renderLabel(labelList.splice(5))}
        >
          <span className={styles.moreLabel}>
            +
            {labelCount - 5}
          </span>
        </Popover>
      </div>
    );
  } else {
    return (
      <div className={styles.label}>
        {
          renderLabel(labelList)
        }
      </div>
    );
  }
});
