import { Affix, Col, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { PUBLIC_IMAGE_URL, setLocationHref } from '../../utils';
import { BlankSegment, DynamicProductCard, Footer, FooterLogo, Navigation, NoData, PaginationCus, ProductCard, RightSider, VendorRelated } from '../components';
import { Filter } from '../components/filter';
import styles from './index.less';
import { MarketStore } from './store';

const store = new MarketStore();

@observer
export default class Market extends React.Component {
  componentDidMount(): void {
    setLocationHref();
  }

  render() {
    return (
      <div className={styles.market}>
        <Navigation className={styles.nav}/>
        <div className={styles.marketContent}>
          <div
            className={styles.bg}
            style={{ background: `url(${PUBLIC_IMAGE_URL}market.png)` }}
          >
            <p className={styles.marketName}>
              {store.marketName}
            </p>
          </div>
          <Affix offsetTop={0}>
            <Tabs
              activeKey={store.activeKey}
              className={`tab ${styles.extraTab}`}
              items={store.tabs}
              onChange={(e) => store.changeTabs(e)}
            />
          </Affix>
          <div className={styles.listContainer}>
            <Filter store={store.filter}/>
            <Spin spinning={store.loading}>
              {
                store.activeKey === 'filterByStyle' ? <MarketByStyle/> : <MarketByStall/>
              }
            </Spin>
            <PaginationCus
              store={store.paginationStore}
              totalCount={store.totalCount}
              totalPageCount={store.totalPageCount}
            />
          </div>
        </div>
        <RightSider store={store.rightSiderStore}/>
        <FooterLogo/>
        <Footer/>
      </div>
    );
  }
}

// 按款式
@observer
class MarketByStyle extends React.Component {
  render() {
    return (
      <div
        className={styles.styleContainer}
        style={{ height: store.list.length ? 'auto' : '400px' }}
      >
        <Row
          className={styles.goodsWrap}
          gutter={[
            12,
            12,
          ]}
        >
          {
            store.list?.map((item, index) => {
              const { goodsId } = item;
              return (
                <Col
                  key={goodsId}
                >
                  <ProductCard
                    activityPrice={item.activityPrice}
                    callback={() => {
                      store.rightSiderStore.getMyStyleWaitNum();
                    }}
                    className={styles.productCard}
                    discountPrice={item.discountPrice}
                    doesAddStyle={item.doesAddStyle}
                    goodsId={item.goodsId}
                    goodsName={item.goodsName}
                    key={nanoid()}
                    mainPic={item.mainPic}
                    marketLabelName={item.marketLabelName}
                    picGroupNum={item.picGroupNum}
                    returnRate={item.returnRate}
                    returnable={item.returnable}
                    sellingPointTag={item.sellingPointTag}
                    virtualSaleNum={item.virtualSaleNum}
                    virtualSelectNum={item.virtualSelectNum}
                    wholePrice={item.wholePrice}
                  />
                </Col>
              );
            })
          }
        </Row>

        {(!store.loading && store.list.length === 0) && (<NoData/>)}
      </div>
    );
  }
}

// 按档口
@observer
class MarketByStall extends React.Component {
  render() {
    if (store.loading) {
      return <BlankSegment/>;
    }
    return (
      <div
        className={styles.stallContainer}
        style={{ height: store.list.length ? 'auto' : '400px' }}
      >
        {
          store.list.length ? store.list.map((item, index) => (
            <VendorRelated
              discountPercent={item.discount}
              goodsPutOnNumWeek={item.goodsPutOnNumWeek}
              key={item.goodsVendorId}
              listContent={<StallGoodsInfo goodsList={item.goodsList}/>}
              mainCategoryName={item.mainCategoryName}
              marketLabelName={item.marketLabelName}
              returnRate={item.returnRate}
              returnable={item.returnable}
              showNameEnterIcon
              showReturnInfo
              type="detail"
              unionFlag={item.unionFlag}
              vendorId={item.goodsVendorId}
              vendorName={item.goodsVendorName}
              width={1148}
            />
          )) : (
            <NoData/>
          )
        }
      </div>
    );
  }
}

const StallGoodsInfo = observer((props) => {
  const { goodsList = []} = props;
  if (goodsList.length === 0) {
    return null;
  } else {
    return (
      <Row gutter={[
        12,
        12,
      ]}
      >
        {
          goodsList.map((item, index) => {
            const {
              activityPrice,
              discountPrice,
              goodsId,
              mainPicUrl,
              visibleType,
              price,
            } = item;
            return (
              <Col
                key={goodsId}
              >
                <DynamicProductCard
                  activityPrice={activityPrice}
                  discountPrice={discountPrice}
                  goodsId={goodsId}
                  mainPicUrl={mainPicUrl}
                  price={price}
                  visibleType={visibleType}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
});
