import { Input, Button } from 'antd';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import styles from './index.less';

export const FilterInputNumberGroup = observer((props) => {
  const { info, onSearch } = props;

  const [
    showBtns,
    setShowBtns,
  ] = useState(false); // 是否展示按钮

  const [
    startValue,
    setStartValue,
  ] = useState(''); // 开始值

  const [
    initialStartValue,
    setInitialStartValue,
  ] = useState(''); // 修改前初始开始值

  const [
    endValue,
    setEndValue,
  ] = useState(''); // 结束值

  const [
    initialEndValue,
    setInitialEndValue,
  ] = useState(''); // 修改前初始结束值

  useEffect(() => {
    setStartValue(info.value && info.value[0]);
    setEndValue(info.value && info.value[1]);
  }, [info.value]);

  return (
    <div
      className={styles.inputNumberGroup}
      style={{ width: showBtns ? '280px' : '140px' }}
    >
      <Input
        allowClear={info.allowClear}
        className={styles.inputNumber}
        maxLength={info.maxLength}
        minLength={info.minLength}
        onChange={(e) => {
          setStartValue(e.target.value.replace(/[^\d]/g, '').replace(/^0{1,}/g, ''));
        }}
        onFocus={() => {
          setShowBtns(true);
        }}
        prefix={info.prefix}
        value={startValue}
      />
      <span className={styles.line}/>
      <Input
        allowClear={info.allowClear}
        className={styles.inputNumber}
        maxLength={info.maxLength}
        minLength={info.minLength}
        onChange={(e) => {
          setEndValue(e.target.value.replace(/[^\d]/g, '').replace(/^0{1,}/g, ''));
        }}
        onFocus={() => {
          setShowBtns(true);
        }}
        prefix={info.prefix}
        value={endValue}
      />
      {
        showBtns && (
          <>
            <Button
              className={styles.inputNumberGroupSubmitBtn}
              onClick={() => {
                let sv: number | string = Number(startValue) || 0;
                let ev: number | string = Number(endValue) || 0;
                if (sv > ev) {
                  sv = Number(endValue) || 0;
                  ev = Number(startValue) || 0;
                  setStartValue(endValue);
                  setEndValue(startValue);
                }
      
                if (sv === 0 && ev === 0) {
                  sv = '';
                  ev = '';
                }

                // 点击确认时：按钮消失、更新初始开始值和初始结束值为最新

                setInitialStartValue(String(sv));
                setInitialEndValue(String(ev));
                setShowBtns(false);

                // 传出最新值
                info.onChangeCallback && info.onChangeCallback([
                  sv,
                  ev,
                ]);

                // 更新最新值
                info.value = [
                  sv,
                  ev,
                ];

                // 搜索
                onSearch();
              }}
            >
              确认
            </Button>
            <Button
              className={styles.inputNumberGroupCancelBtn}
              onClick={() => {
                // 点击取消时：按钮消失、恢复初始开始值和初始结束值
                setStartValue(initialStartValue);
                setEndValue(initialEndValue);
                setShowBtns(false);
              }}
            >
              取消
            </Button>
          </>
        )
      }
    </div>
  );
});
