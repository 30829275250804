import React from 'react';
import zeroData from '../../../assets/images/zeroData.png';
import styles from './index.less';

// 款式列表-空数据
export const NoData = () => {
  return (
    <div className={styles.emptyWrapper}>
      <img
        src={zeroData}
        style={{
          height: '80px',
          marginBottom: '8px',
        }}
      />
      <div style={{ color: '#000' }}>
        暂无数据！
      </div>
    </div>
  );
};
